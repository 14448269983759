import Inferno from 'inferno'
import Component from 'inferno-component'
import classNames from 'classnames'

import { animationEvent } from '@misc/utilities'

class Modal extends Component<any, any> {

  public wrapperElement

  constructor(props) {
    super(props)

    this.state = {
      isFading: false,
      padding: 20
    }

    this.scrollbarSet()

    if(props.beforeOpen) {
      props.beforeOpen()
    }

    // events
    this.handleClickClose = this.handleClickClose.bind(this)
    this.handleClickBackground = this.handleClickBackground.bind(this)
  }

  componentDidMount() {
    if(this.props.onOpen)
      this.props.onOpen(this.wrapperElement)
  }

  handleClickClose(e) {
    e.preventDefault()
    e.stopPropagation()

    this.closeModal()
  }

  handleClickBackground(e) {
    if(!e.target.closest('.modal'))
      this.closeModal()
  }

  closeModal() {
    this.setState({ isFading: true })

    if(this.props.beforeClose)
      this.props.beforeClose(this.wrapperElement);

    return animationEvent ?
      this.wrapperElement.addEventListener(animationEvent, e => this._closeModal()) :
      this._closeModal()
  }

  _closeModal() {

    if(this.props.onClose)
      this.props.onClose()

    this.scrollbarUnset()
    this.props.destroy()
  }

  scrollbarSet() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    document.body.classList.add('modal-open')
    document.body.style.paddingRight = scrollbarWidth + 'px'
  }

  scrollbarUnset() {
    document.body.classList.remove('modal-open')
    document.body.style.paddingRight = '0'
  }

  render() {

    const { width, height, nopadding, type } = this.props

    let modalStyles = {
      maxWidth: nopadding && width ? width : width + this.state.padding * 2,
    }

    let modalContentStyles = {
      padding: nopadding ? null : this.state.padding,
      height: height || null,
    }

    const classes = classNames(
      'animate',
      {
        'fading': this.state.isFading,
        'nopadding': nopadding,
        [`modal-${type}`]: type
      }
    )

    const wrapperProps = {
      ref: node => this.wrapperElement = node,
      className: `modal-wrapper ${classes}`,
      onClick: this.handleClickBackground
    }

    return (
      <div {...wrapperProps}>
        <div class="modal" style={modalStyles}>
          <div class="close-button" onClick={this.handleClickClose}></div>
          <div class="modal-inner">
            { this.renderTitle() }
            <div class="modal-content" style={modalContentStyles}>
              { this.renderContent() }
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTitle() {

    if(!this.props.title) { return }

    const { title } = this.props

    return (
      <div class="modal-title">{ title }</div>
    )
  }

  renderContent() {
    return this.props.children
  }
}

export default Modal
