import { render } from 'inferno'
import extend from 'xtend'
import Modal from '@components/modal'

import {
	forEach, getElementByHashLink, sanitizeElementData
} from '@misc/utilities'

import { infernoRemoveRootNode } from '@misc/utilities-inferno'

const allowedModalLinkProps = [
	'notitle', 'nopadding', 'iframe',
	'width', 'height'
]

const bootstrapModals = () => {
  console.debug('Bootstrapping Modals...')
	connectNodes('.modalLink_inline', openModal)
}

const connectNodes = (selector, callback) => {
	const nodeList = document.querySelectorAll(selector)
	let element
	forEach(nodeList, link => {
		link.addEventListener('click', e => {
			e.preventDefault()
			element = e.target.closest(selector)
			callback(element)
		})
	})
}

const openModal = (link) => {
	let title = link.textContent
	const contentElem = getElementByHashLink(link)

  if(!contentElem) return

	const content = contentElem.innerHTML

	if(!content) return

	const dataOptions = sanitizeElementData(
		link, {
			allowedKeys: allowedModalLinkProps,
			sanitizeKeyFunc: key => key.replace(/options/, '').toLowerCase()
		})

	if(contentElem.dataset && contentElem.dataset.title) {
		title = contentElem.dataset.title
	}

	const defaultOptions = {
		html: true,
	}

	const options = extend({}, defaultOptions, dataOptions)

	renderModal({
		title, content, options
	})
}

const renderModal = (data = {}) => {

	let modalContainer = document.getElementById('modal-container')

	if(!modalContainer) {
		modalContainer = document.createElement('div')
		modalContainer.id = 'modal-container'
		document.body.insertBefore(modalContainer, document.body.firstChild)
	}

	const props = {
		destroy: infernoRemoveRootNode(modalContainer),
		...data
	}

	render(
		<Modal {...props} />,
		modalContainer
	)
}

export default bootstrapModals
